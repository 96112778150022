import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/worldwide",
    name: "worldwide",
    component: () =>
      import(/* webpackChunkName: "worldwide" */ "../views/Worldwide.vue"),
  },
  {
    path: "/exhibitions",
    name: "exhibitions",
    component: () =>
      import(/* webpackChunkName: "exhibitions" */ "../views/Exhibitions.vue"),
  },
  {
    path: "/venues/rimini",
    name: "VenuesRimini",
    component: () =>
      import(/* webpackChunkName: "VenuesRimini" */ "../views/Rimini.vue"),
  },
  {
    path: "/venues/vicenza",
    name: "VenuesVicenza",
    component: () =>
      import(/* webpackChunkName: "VenuesVicenza" */ "../views/Vicenza.vue"),
  },
  {
    path: "/venues/vicenza/local-area",
    name: "VicenzaLocalArea",
    component: () =>
      import(
        /* webpackChunkName: "VenuesVicenza" */ "../views/VicenzaLocalArea.vue"
      ),
  },
  {
    path: "/venues/rimini/local-area",
    name: "RiminiLocalArea",
    component: () =>
      import(
        /* webpackChunkName: "RiminiLocalArea" */ "../views/RiminiLocalArea.vue"
      ),
  },
  {
    path: "/venues/rimini/center",
    name: "RiminiCenter",
    component: () =>
      import(
        /* webpackChunkName: "RiminiCenter" */ "../views/RiminiCenter.vue"
      ),
  },
  {
    path: "/venues/vicenza/center",
    name: "VicenzaCenter",
    component: () =>
      import(
        /* webpackChunkName: "VicenzaCenter" */ "../views/VicenzaCenter.vue"
      ),
  },
  {
    path: "/news",
    name: "News",
    component: () => import(/* webpackChunkName: "News" */ "../views/News.vue"),
  },
  {
    path: "/news/business",
    name: "NewsBusiness",
    component: () =>
      import(
        /* webpackChunkName: "NewsBusiness" */ "../views/news/Business.vue"
      ),
  },
  {
    path: "/news/0",
    name: "News0",
    component: () =>
      import(/* webpackChunkName: "News0" */ "../views/news/News0.vue"),
  },
  {
    path: "/news/1",
    name: "News1",
    component: () =>
      import(/* webpackChunkName: "News1" */ "../views/news/News1.vue"),
  },
  {
    path: "/news/2",
    name: "News2",
    component: () =>
      import(/* webpackChunkName: "News2" */ "../views/news/News2.vue"),
  },
  {
    path: "/news/3",
    name: "News3",
    component: () =>
      import(/* webpackChunkName: "News3" */ "../views/news/News3.vue"),
  },
  {
    path: "/news/4",
    name: "News4",
    component: () =>
      import(/* webpackChunkName: "News4" */ "../views/news/News4.vue"),
  },
  {
    path: "/news/5",
    name: "News5",
    component: () =>
      import(/* webpackChunkName: "News5" */ "../views/news/News5.vue"),
  },
  {
    path: "/news/6",
    name: "News6",
    component: () =>
      import(/* webpackChunkName: "News6" */ "../views/news/News6.vue"),
  },
  {
    path: "/news/7",
    name: "News7",
    component: () =>
      import(/* webpackChunkName: "News7" */ "../views/news/News7.vue"),
  },
  {
    path: "/news/8",
    name: "News8",
    component: () =>
      import(/* webpackChunkName: "News8" */ "../views/news/News8.vue"),
  },
  {
    path: "/news/9",
    name: "News9",
    component: () =>
      import(/* webpackChunkName: "News9" */ "../views/news/News9.vue"),
  },
  {
    path: "/news/10",
    name: "News10",
    component: () =>
      import(/* webpackChunkName: "News10" */ "../views/news/News10.vue"),
  },
  {
    path: "/news/11",
    name: "News11",
    component: () =>
      import(/* webpackChunkName: "News11" */ "../views/news/News11.vue"),
  },
  {
    path: "/news/12",
    name: "News12",
    component: () =>
      import(/* webpackChunkName: "News12" */ "../views/news/News12.vue"),
  },
  {
    path: "/ys",
    name: "Ys",
    component: () => import(/* webpackChunkName: "Ys" */ "../views/Ys.vue"),
  },
  {
    path: "/job",
    name: "Job",
    component: () => import(/* webpackChunkName: "Job" */ "../views/Job.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
