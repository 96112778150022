<template>
  <div class="page-footer">
    <div class="page-footer-body">
      <img
        class="page-footer-logo"
        @click="gotoUrl('/')"
        src="../assets/logo2.png"
      />
      <div class="page-footer-content">
        <a href="/news/0">集团概况</a>
        <a href="/news">新闻中心</a>

        <a href="/exhibitions">展会一览</a>
        <a href="/job">联系我们</a>
        <a href="/worldwide">全球网络</a>
        <a href="/ys">隐私政策</a>
        <div></div>
      </div>
    </div>
    <a
      class="page-footer-icp"
      href="https://beian.miit.gov.cn/#/Integrated/index"
      target="_blank"
      >沪ICP备2024082304号-1</a
    >
  </div>
</template>
<script setup>
const gotoUrl = (url) => {
  location.href = url;
};
</script>
<style lang="scss">
.page-footer {
  height: 220px;
  background: #e3e3e3;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  &-body {
    width: 1200px;
    position: relative;
    height: 120px;
    padding: 10px 120px 60px 300px;
    margin-top: 40px;
  }
  &-logo {
    width: 180px;
    height: 120px;
    position: absolute;
    left: 0;
    top: -20px;
    cursor: pointer;
  }
  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    color: #343434;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height: 120px;
    text-align: left;
    div {
      cursor: pointer;
    }
  }
  &-icp {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(---003, #8c8c8c);
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    bottom: 0px;
    width: 100vw;
    background-color: #fff;
  }
}
@media (max-width: 768px) {
  .page-footer {
    height: 145px;
  }
  .page-footer-logo {
    display: none;
  }
  .page-footer-content {
    width: 345px;
    grid-template-rows: 1fr 1fr;
    height: 60px;
    a {
      text-align: center;
    }
  }
  .page-footer-body {
    padding: 0 15px;
    margin-top: 15px;
  }
}
</style>
