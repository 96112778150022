<template>
  <router-view />
</template>

<style lang="scss">
html,
body {
  overflow-x: hidden;
  width: 100vw !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  width: 100vw;
}
* {
  box-sizing: border-box;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: inherit;
  text-decoration: underline;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

a:active {
  color: inherit;
  text-decoration: none;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.newsd {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-cover {
    width: 100%;
    margin-bottom: 24px;
  }
  &-header {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100px;
    background-color: #fff;
  }
  &-body {
    width: 1200px;

    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #1a1a1a;
    margin: 36px 0 80px;
    &-title {
      cursor: pointer;
      font-family: PingFang SC;
      font-size: 36px;
      font-weight: 600;
      line-height: 46px;
      text-align: left;
      margin-bottom: 36px;
    }
    &-desc {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      padding: 20px;
      background: #f7f7f7;
      margin-bottom: 36px;
      color: #343434;
    }
    &-subtitle {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin: 42px 0 14px;
    }
  }
}
@media (max-width: 768px) {
  #app {
    padding-top: 111px;
  }
  .newsd-body {
    width: 375px;
    padding: 0 15px;
    margin: 10px 0;
  }
  .newsd-body-title {
    margin: 20px 0 10px;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
