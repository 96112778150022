<template>
  <div class="home">
    <Header class="home-header" />
    <div class="home-body">
      <div class="home-body-header">
        <div class="home-body-left">
          我们在意大利和世界各地组织贸易展览会和会议。我们建立行业、关系、经验网络。我们关心地球。#COMMUNITYCATALYST
        </div>
        <img class="home-body-right" src="../assets/image17.png" />
      </div>
      <div class="home-body-video">
        <video
          src="https://ieg-expo.com.cn/ieg_mix_hp_sito_SEPT_2022.mp4"
          width="320"
          height="240"
          autoplay
          muted
          controls
          loop
        ></video>
      </div>
      <div class="home-body-desc">
        <div class="home-body-desc-item">
          <div class="home-body-desc-title">概述</div>
          <div class="home-body-desc-content">
            意大利展览集团Italian Exhibition
            Group在米兰上市，总部设在意大利，在世界各地组织国际展览会、活动和会议。作为IEG在中国的子公司，艾意吉展览（上海）有限公司整合意大利展览集团全球资源，助力中国企业拓宽国内外市场。
          </div>
          <a class="home-body-desc-more" href="/news/0">点击查看</a>
        </div>
        <a-divider type="vertical" style="height: 260px; border-color: #fff" />
        <div class="home-body-desc-item">
          <div class="home-body-desc-title">业务</div>
          <div class="home-body-desc-content">
            艾意吉展览（上海）有限公司的业务范围包括六大板块的相关展会，包括：食品和饮料、珠宝与时尚、健康与体育、旅游与酒店、生活方式与娱乐、绿色与技术。艾意吉展览致力于在国内举办品牌展会、组织中国企业拓宽海外市场，以及展馆运营、会议活动赛事组织、收购兼并等业务拓展。
          </div>
          <a class="home-body-desc-more" href="/news/business">点击查看</a>
        </div>
        <a-divider type="vertical" style="height: 260px; border-color: #fff" />
        <div class="home-body-desc-item">
          <div class="home-body-desc-title">新闻</div>
          <div class="home-body-desc-content">
            <a class="home-body-news" href="/news/4"
              >IEG，第一家获得性别平等认证的贸易展览公司</a
            >
            <a class="home-body-news" href="/news/6">
              IBE驾驶体验 一次成功的体验，商业与巴士客车行业未来的相遇
            </a>
            <a class="home-body-news" href="/news/5"
              >IEG, ECOMONDO意大利国际绿色能源与环保展</a
            >
            <a class="home-body-news" href="/news/3"
              >IEG，TTG旅行体验和InOut酒店工程建设采购</a
            >
          </div>
          <a class="home-body-desc-more home-body-news" href="/news"
            >更多新闻</a
          >
        </div>
      </div>
      <div class="home-body-divider"></div>
      <div class="home-body-jt">
        <img class="home-body-jt-bg" src="../assets/ieg_alpha_logo.png" />
        <div class="home-body-jt-title">意大利展览集团</div>
        <div class="home-body-jt-desc">
          意大利展览集团（Italian Exhibition Group
          S.p.A.，缩写IEG）是一家在米兰泛欧交易所（Euronext
          Milan）上市的公司，该交易所是由意大利证券交易所（Borsa Italiana
          S.p.A.）组织和管理的受监管市场。多年来，IEG集团通过位于里米尼（Rimini）和维琴察（Vicenza）的展览机构而不断发展，在展览会和会议活动的组织方面居于意大利领先地位。它经营六条业务线（主办活动，大会活动，承办活动，相关服务，出版，体育活动）。通过在海外市场的不断拓展，以及和全球或当地主办的合作合资，IEG集团目前已在美国、阿拉伯联合酋长国、沙特阿拉伯、中国、墨西哥、德国、新加坡、巴西等国家设有子公司，跻身成为欧洲领先的会展集团之一。
          2023 年，IEG在里米尼和维琴察的展览和会议场馆综合体共组织或主办了 56
          场展览会和 126 场会议活动。
        </div>
        <div class="home-body-jt-ls">
          隶属于：<img
            src="https://www.iegexpo.it/images/homepage/logo_siso_jpg_no_margin.jpg"
            alt=""
          />
          <img
            src="https://www.iegexpo.it/images/homepage/ufi-logo.png"
            alt=""
          />
        </div>
        <div class="home-body-jt-card">
          <a
            class="home-body-jt-card-item"
            href="https://brochure.iegexpo.it/brochure/cover"
          >
            <div class="home-body-jt-card-title">概述</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/templates/yootheme/cache/ieg_company_profile-ad141228.jpeg"
            />
          </a>
          <a-divider
            type="vertical"
            style="height: 194px; border-color: #ebebeb"
          />
          <a
            class="home-body-jt-card-item"
            href="https://www.iegexpomagazine.com/"
          >
            <div class="home-body-jt-card-title">杂志</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/templates/yootheme/cache/immagine_IEG_EXPO_MAGAZINE_1200x800px-5bdf1cd1.jpeg"
            />
          </a>
          <a-divider
            type="vertical"
            style="height: 194px; border-color: #ebebeb"
          />
          <a
            class="home-body-jt-card-item"
            href="https://www.iegexpo.it/images/IEG_GREENCORE.pdf"
          >
            <div class="home-body-jt-card-title">IEG GREEN CORE</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/images/ieg_green_core_051023.png"
            />
          </a>
          <a-divider
            type="vertical"
            style="height: 194px; border-color: #ebebeb"
          />
          <div class="home-body-jt-card-item">
            <div class="home-body-jt-card-title">证书</div>
            <img
              class="home-body-jt-card-img"
              src="https://www.iegexpo.it/templates/yootheme/cache/certificazioni_ieg_2023-e3de4470.png"
            />
            <a-dropdown>
              <div class="home-body-jt-card-btn">下载</div>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ITALIAN_EXHIBITION_GROUP_SPA_ISO_14001-ITA.pdf"
                    >
                      Certificazione ISO 14001
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ITALIAN_EXHIBITION_GROUP_SPA_ISO_45001_ITA.pdf"
                    >
                      Certificazione ISO 45001
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/iso/Italian_Exhibition_Group_Rimini_Expo_Centre_2022.pdf"
                    >
                      Certificazione GBAC Italian Exhibition Group
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/iso/Italian_Exhibition_Group_Palas_Rimini_2022.pdf"
                    >
                      Certificazione GBAC Palacongressi
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/iso/Italian_Exhibition_Group_Vicenza_Expo_Centre_2022.pdf"
                    >
                      Certificazione GBAC VICC
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ISO_20121_ITA_VENUES.pdf"
                    >
                      Certificazione ISO 20121 Venues
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="https://www.iegexpo.it/images/pdf/ISO_20121_ITA_ECOMONDO.pdf"
                    >
                      Certificazione ISO 20121 Ecomondo
                    </a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </div>
      <div class="home-body-divider"></div>
      <div class="home-body-zh">
        <div class="home-body-zh-title">近期展会</div>
        <a class="home-body-zh-more" href="/exhibitions">了解更多</a>
        <div class="home-body-zh-list">
          <div
            class="home-body-zh-item"
            :key="exb.time"
            v-for="exb in exhibitions.slice(0, 9)"
          >
            <a class="home-body-zh-item-img" :href="exb.url">
              <img :src="require(`../assets/exhibitions/${exb.logo}`)" alt="" />
            </a>
            <a class="home-body-zh-item-title" :href="exb.url">{{
              exb.name
            }}</a>
            <div class="home-body-zh-item-content">
              {{ exb.location }}<br />{{ exb.time }}
            </div>
          </div>
        </div>
        <div class="home-body-zh-subtitle">官方指定承运商</div>
        <div class="home-body-zh-logo">
          <img src="https://www.iegexpo.it/images/logo_trenitalia.svg" />
        </div>
      </div>
      <div class="home-body-divider"></div>
      <div class="home-body-zg">
        <div class="home-body-zg-title">展馆</div>
        <div class="home-body-zg-content">
          <div class="home-body-zg-item">
            <a class="home-body-zg-item-title" href="/venues/rimini"
              >里米尼博览中心</a
            >
          </div>
          <div class="home-body-zg-item">
            <a
              class="home-body-zg-item-title"
              href="https://www.riminipalacongressi.it/"
              >里米尼会展中心</a
            >
          </div>
          <div class="home-body-zg-item">
            <a class="home-body-zg-item-title" href="/venues/vicenza/"
              >维琴察博览中心</a
            >
          </div>
          <div class="home-body-zg-item">
            <a
              class="home-body-zg-item-title"
              href="https://www.vicenzaconventioncentre.it/"
              >维琴察会展中心</a
            >
          </div>
        </div>
      </div>
      <div class="home-body-divider"></div>
    </div>
    <Footer class="home-footer" />
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
const exhibitions = [
  {
    time: "2024年7月29-30日",
    location: "纽约，美国",
    logo: "CHILDREN'S.png",
    name: "美国婴童时尚产业博览会",
    desc: "The Elevated International Baby and Children\n\nFashion Industry Expo in New York City",
    url: "https://www.childrenshow.com/",
    group: "生活方式与娱乐",
  },
  {
    time: "2024年9月6-8日",
    location: "意大利",
    logo: "VoClock800-7c4edb9f.png",
    name: "VO'CLOCK PRIVÉ 国际手表展",

    url: "https://www.vicenzaoro.com/en/vo-clock",
    group: "珠宝与时尚",
  },
  {
    time: "2024年9月6-10日",
    location: "意大利",
    logo: "VICENZAORO.png",
    name: "VICENZAORO SEPTEMBER 国际珠宝展",
    desc: "International Jewellery Show",
    url: "https://www.vicenzaoro.com/en",
    group: "珠宝与时尚",
  },
  {
    time: "2024年9月6-10日",
    location: "意大利",
    logo: "LOGO_PALAKISS.png",
    name: "PALAKISS SUMMER\n国际珠宝交付展夏季展",

    url: "https://www.palakiss.com/en/",
    group: "",
  },
  {
    time: "2024年9月11-14日",
    location: "巴西",
    logo: "LOGO_fesqua.png",
    name: "FESQUA 巴西门窗和框架展",
    desc: "The Biggest and Most Important Event for the Frame Market in Latin America",
    url: "https://fesqua.com.br/",
    group: "绿色与科技",
  },
  {
    time: "2024年9月11-14日",
    location: "巴西",
    logo: "LOGO_EBRATS.png",
    name: "EBRATS 巴西表面处理展",
    desc: "Brazilian Surface Treatment Encounter and Exposition",
    url: "https://www.ebrats.com.br/",
    group: "绿色与科技",
  },
  {
    time: "2024年9月19-22日~2024年9月26-29日",
    location: "意大利",
    logo: "LOGO_abilmente-01.png",
    name: "ABILMENTE 国际工艺品博览会",
    desc: "The Creative Ideas Show",
    url: "https://www.abilmente.org/en/turin",
    group: "生活方式与娱乐",
  },
  {
    time: "2024年9月24-27日",
    location: "意大利",
    logo: "LOGO_tecna.png",
    name: "TECNA 国际陶瓷技术展",
    desc: "International Exhibition of Technologies and Supplies for Surfaces",
    url: "https://en.tecnaexpo.com/",
    group: "绿色与科技",
  },
  {
    time: "2024年10月9-11日",
    location: "意大利",
    logo: "LOGO_TTG.png",
    name: "TTG 国际旅游业展览会",
    desc: "B2B show for the promotion of global tourism",
    url: "https://en.ttgexpo.it/",
    group: "旅游和酒店",
  },
  {
    time: "2024年10月9-11日",
    location: "墨西哥",
    logo: "LOGO_ITM.png",
    name: "墨西哥智能制造工业展",
    desc: "Platform for digital transformation and Intelligent Manufacturing in Mexico",
    url: "https://industrialtransformation.mx/",
    group: "绿色与科技",
  },
  {
    time: "2024年10月9-11日",
    location: "意大利",
    logo: "LOGO_inout.png",
    name: "InOut 酒店设计、海滩、花园和室外布景、建筑和室内设计展",
    desc: "The Contract Community",
    url: "https://en.inoutexpo.it/",
    group: "旅游和酒店",
  },
  {
    time: "2024年10月25-27日",
    location: "迪拜",
    logo: "LOGO_DMS.png",
    name: "DUBAI MUSCLE SHOW\n迪拜国际健身展",
    desc: "The Middle East’s Leading Fitness & Bodybuilding Show",
    url: "https://www.dubaimuscleshow.com/",
    group: "健身与运动",
  },
  {
    time: "2024年10月25-27日",
    location: "迪拜",
    logo: "LOGO_DA.png",
    name: "Dubai Active Industry\n迪拜运动展",
    desc: "The Middle East’s Leading International Fitness & Wellness Trade Show",
    url: "https://dubaiactiveshow.com/",
    group: "健身与运动",
  },
  {
    time: "2024年10月26-27日",
    location: "意大利",
    logo: "LOGO-ESOTIKA.png",
    name: "国际宠物展",

    url: "https://www.esotikapetshow.it/fiera/rovigo-ottobre-2023/",
    group: "生活方式与娱乐",
  },
  {
    time: "2024年11月5-8日",
    location: "意大利",
    logo: "ecomondo-logo-2023-5.jpg",
    name: "ECOMONDO\n国际绿色能源及环保展",
    desc: "International Trade Fair of Material & Energy Recovery and Sustainable Development",
    url: "https://en.ecomondo.com/",
    group: "绿色与科技",
  },
  {
    time: "2024年11月6-8日",
    location: "意大利",
    logo: "LOGO_A&T.png",
    name: "智能制造、智慧物流、检测和流程控制展",
    desc: "The Trade Show dedicated to Innovation, Technologies, Reliability and Skills 4.0 – 5.0",
    url: "https://vicenza.aetevent.com/default.aspx?site=ENG",
    group: "绿色与科技",
  },
  {
    time: "2024年11月12-14日",
    location: "迪拜",
    logo: "LOGO_JGTD.png",
    name: "JGT DUBAI 迪拜珠宝、宝石和技术展",
    desc: "Jewellery, Gem & Technology Dubai",
    url: "https://www.jgtdubaijewelleryshow.com/",
    group: "珠宝与时尚",
  },
  {
    time: "2024年11月19-21日",
    location: "意大利",
    logo: "LOGO_IBE.jpeg",
    name: "IBE\n巴士客车展",
    desc: "INTERMOBILITY AND BUS EXPO",
    url: "https://en.expoibe.com/",
    group: "绿色与科技",
  },
  {
    time: "2024年11月22-24日",
    location: "巴西",
    logo: "btff.png",
    name: "BTFF\n巴西健身展",
    desc: "BRASIL TRADING FITNESS FAIR",
    url: "https://btff.com.br/",
    group: "健身与运动",
  },
  {
    time: "2024年",
    location: "意大利",
    logo: "LOGO_cosmofood.png",
    name: "国际食品饮料展",

    url: "https://en.cosmofood.it/",
    group: "食品与饮料",
  },
  {
    time: "2025年1月17-21日",
    location: "意大利",
    logo: "VICENZAORO.png",
    name: "VICENZAORO珠宝展",
    desc: "International Jewellery Show",
    url: "https://www.vicenzaoro.com/en",
    group: "珠宝与时尚",
  },
  {
    time: "2025年1月17-21日",
    location: "意大利",
    logo: "VO_Vantage.png",
    name: "VO VINTAGE国际珠宝和古董手表展",
    desc: "The key event for premium vintage watches and jewellery.",
    url: "https://www.vicenzaoro.com/en/vicenzaoro-vintage",
    group: "珠宝与时尚",
  },
  {
    time: "2025年1月17-21日",
    location: "意大利",
    logo: "VICENZAORO.png",
    name: "T.GOLD国际珠宝设备和加工技术展",
    desc: "The international Show for jewellery machinery and the most innovative technologies applied to gold and jewellery processing.",
    url: "https://www.vicenzaoro.com/en/t-gold",
    group: "珠宝与时尚",
  },
  {
    time: "2025年1月18-22日",
    location: "意大利",
    logo: "SIGEP.png",
    name: "SIGEP 国际手工冰淇淋、糕点、烘焙和咖啡展",
    desc: "International Trade Show of Artisan Gelato, Pastry, Bakery and the Coffee World",
    url: "https://en.sigep.it/",
    group: "食品与饮料",
  },
  {
    time: "2025年2月7-9日",
    location: "意大利",
    logo: "LOGO_pescareshow.png",
    name: "PESCARE 国际钓鱼和划船运动展",
    desc: "THE INTERNATIONAL EVENT FOR FISHING AND BOATING SPORTS",
    url: "https://www.pescareshow.it/en/",
    group: "健身与运动",
  },
  {
    time: "2025年2月16-18日",
    location: "意大利",
    logo: "LOGO_BBT.png",
    name: "BBTECH EXPO \n国际啤酒和饮料技术专业贸易展览会",
    desc: "The professional trade fair for beer and beverage technologies",
    url: "https://en.bbtechexpo.com/",
    group: "食品与饮料",
  },
  {
    time: "2025年2月16-18日",
    location: "意大利",
    logo: "LOGO_B&F.png",
    name: "BEER & FOOD ATTRACTION\n户外啤酒、饮料和食品展",
    desc: "The Eating Out Experience Show",
    url: "https://en.beerandfoodattraction.it/",
    group: "食品与饮料",
  },
  {
    time: "2025年2月17-19日",
    location: "意大利",
    logo: "LOGO_enada_spring.jpg",
    name: "ENADA PRIMAVERA\n国际娱乐与游戏展",
    desc: "International Amusement & Gaming Show",
    url: "https://en.enada.it/",
    group: "生活方式与娱乐",
  },
  {
    time: "2025年2月21-22日",
    location: "巴西",
    logo: "Logo-Black-Green.png",
    name: "Riyadh Muscle 沙特国际健身展",
    desc: "Saudi Arabia’s Leading International Fitness & Bodybuilding Event",
    url: "https://riyadhmuscle.com/",
    group: "健身与运动",
  },
  {
    time: "2025年3月5-7日",
    location: "意大利",
    logo: "LOGO_K.EY.png",
    name: "K.EY 国际能源展",
    desc: "The Energy Transition Expo",
    url: "https://en.key-expo.com/",
    group: "绿色与科技",
  },
  {
    time: "2025年3月5-7日",
    location: "墨西哥",
    logo: "ECOMONDO_MEXICO.png",
    name: "ECOMONDO MEXICO\n墨西哥国际绿色能源及环保展",
    desc: "The environmental technology event designed for the Latin American market",
    url: "https://hfmexico.mx/ecomondo/en/",
    group: "绿色与科技",
  },
  {
    time: "2025年3月5-7日",
    location: "墨西哥",
    logo: "logoREPLUS.png",
    name: "墨西哥太阳能和储能展",
    desc: "The leading fair for solar energy and storage in Mexico and Latin America",
    url: "https://www.hfmexico.mx/solarpowermexico/",
    group: "绿色与科技",
  },
  {
    time: "2025年3月23-25日",
    location: "意大利",
    logo: "LOGO_MIR.jpg",
    name: "MIR国际现场娱乐、音响和舞台照明博览会",
    desc: "Live Entertainment Expo",
    url: "https://en.mirtechexpo.com/",
    group: "生活方式与娱乐",
  },
  {
    time: "2025年3月27-29日",
    location: "中国 成都",
    logo: "LOGO_ecomondo-china.png",
    name: "ECOMONDO China (CDEPE)\n成都环保展",
    desc: "Leading the Ecological and Energy Transition in Western China",
    url: "https://www.cdepe.com/",
    group: "绿色与科技",
  },
  {
    time: "2025年4月24-26日",
    location: "中国 深圳",
    logo: "20220615164231854bd8d4.jpg",
    name: "SIGEP China\n中国手工冰淇淋、糕点、烘焙和咖啡展",
    desc: "Southern China’s International Expo dedicated to the production of Gelato, Bakery & Coffee",
    url: "https://www.sigepcn.com/cn/",
    group: "食品与饮料",
  },
  {
    time: "2025年5月10-13日",
    location: "意大利",
    logo: "LOGO_oroarezzo-01.png",
    name: "OROAREZZO国际珠宝展",
    desc: "International Jewelry Exhibition",
    url: "https://www.oroarezzo.it/en/",
    group: "珠宝与时尚",
  },
  {
    time: "2025年5月15-17日",
    location: "意大利",
    logo: "LOGO_Expodental.png",
    name: "国际牙科展",

    url: "https://www.expodental.it/en/",
    group: "绿色与科技",
  },
  {
    time: "2025年6月3-5日",
    location: "巴西",
    logo: "droneshow-robotics-retina-400x85.png",
    name: "DRONE SHOW\n巴西国际无人机展",
    desc: "Leading Event on Drones and connected technologies",
    url: "https://droneshowla.com/en/",
    group: "绿色与科技",
  },
  {
    time: "2025年6月3-5日",
    location: "巴西",
    logo: "img-retina.png",
    name: "MUNDO GEO CONNECT\n巴西国际地质技术和地质信息展",
    desc: "Leading Event on geotechnology and geographical information technologies",
    url: "https://mundogeoconnect.com/2024/en/",
    group: "绿色与科技",
  },
  {
    time: "2025年6月3-5日",
    location: "巴西",
    logo: "img-logo-expo-evtol-color.png",
    name: "Expo eVTOL \n空中交通和创新飞机展",
    desc: "Advanced Air Mobility and Innovative Aircraft",
    url: "https://expoevtol.com/en/",
    group: "绿色与科技",
  },
  {
    time: "2025年6月3-5日",
    location: "巴西",
    logo: "spaceBrShow.jpg",
    name: "SPACEBR SHOW\n巴西空间观测展",
    desc: "Leading Event on Space sector technologies",
    url: "https://spacebrshow.com/en/",
    group: "绿色与科技",
  },
  {
    time: "2025年6月3-5日",
    location: "巴西",
    logo: "sb1.png",
    name: "Smart City Business Brazil\n巴西智慧城市展",
    desc: "The main technology and innovation fair for Smart Cities",
    url: "https://www.scbamerica.com/en",
    group: "绿色与科技",
  },
  {
    time: "2025年9月5-9日",
    location: "意大利",
    logo: "VICENZAORO.png",
    name: "VICENZAORO珠宝展",
    desc: "International Jewellery Show",
    url: "https://www.vicenzaoro.com/en",
    group: "健身与运动",
  },
  {
    time: "2025年",
    location: "墨西哥",
    logo: "LOGO_worldseafood.png",
    name: "墨西哥国际渔业和水产养殖展览会",
    desc: "International B2B Fisheries and Aquaculture Exhibition",
    url: "https://hfmexico.mx/worldseafood/en/",
    group: "生活方式与娱乐",
  },
  {
    time: "2025年",
    location: "墨西哥",
    logo: "LOGO_agro_color.png",
    name: "AGRO TECH MEXICO\n墨西哥农业科技展",
    desc: "The first B2B exhibition specialized in Industry 4.0 dedicated to agriculture",
    url: "https://hfmexico.mx/agrotechmexico/en/",
    group: "绿色与科技",
  },
  {
    time: "2025年",
    location: "意大利",
    logo: "RIMINIWELLNESS 23_logo.png",
    name: "RIMINI WELLNESS\n里米尼国际健身展",
    desc: "The Wellness Experience Show",
    url: "https://en.riminiwellness.com/",
    group: "健身与运动",
  },
  {
    time: "2025年",
    location: "新加坡",
    logo: "LOGO_SIGEP-Asia.png",
    name: "SIGEP Asia\n亚洲手工冰淇淋、糕点、烘焙和咖啡展",
    desc: "International Trade Show of Artisanal Gelato, Pastry, Bakery, Coffee and Tea World",
    url: "https://sigepasia.com.sg/",
    group: "食品与饮料",
  },
  {
    time: "2025年",
    location: "墨西哥",
    logo: "LOGO_MACS.png",
    name: "MEXICO ACTIVE & SPORTS EXPO\n墨西哥体育、健身和健康展",
    desc: "The leading platform for the sport, fitness and wellness industry in Latin America",
    url: "https://www.macsexpo.mx/",
    group: "健身与运动",
  },
];
</script>
<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-header {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100px;
    background-color: #fff;
  }
  &-body {
    width: 1200px;
    &-header {
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
    }
    &-left {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      width: 308px;
      height: 72px;
    }
    &-right {
      width: 255.14px;
      height: 96px;
    }
    &-news {
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
    &-video {
      height: 400px;
      display: flex;
      align-items: center;
      overflow: hidden;
      video {
        height: 788px;
        width: 1200px;
      }
    }
    &-desc {
      height: 280px;
      display: flex;
      align-items: center;
      margin: 20px 0 40px;
      &-item {
        flex: 1;
        padding: 20px 32px;
        position: relative;
        text-align: left;
        height: 280px;
        background: #f7f7f7;
      }
      &-title {
        color: #000;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        height: 26px;
      }
      &-content {
        color: #343434;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 16px 0;
      }
      &-more {
        color: #343434;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        position: absolute;
        left: 32px;
        bottom: 20px;
      }
    }
    &-jt {
      padding-top: 40px;
      position: relative;
      &-title {
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
      }
      &-desc {
        overflow: hidden;
        color: #343434;
        text-align: center;
        text-overflow: ellipsis;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 24px 0;
      }
      &-bg {
        position: absolute;
        width: 148.8px;
        height: 148px;
        top: 40px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
      }
      &-ls {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        img {
          height: 20px;
          margin-left: 10px;
        }
      }
      &-card {
        margin: 30px 0 40px;
        display: flex;
        justify-content: center;
        &-item {
          width: 270px;
          height: 196px;
          padding: 0 20px;
          position: relative;
        }
        &-title {
          color: #343434;
          font-family: PingFang SC;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
        }
        &-img {
          width: 230px;
          height: 153.333px;
          margin-top: 16px;
        }
        &-btn {
          font-size: 12px;
          padding: 0 12px;
          height: 24px;
          border: 1px solid rgba(255, 255, 255, 0.9);
          color: #343434;
          position: absolute;
          border-radius: 4px;
          cursor: pointer;
          bottom: 20px;
          left: 105px;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }
    &-zh {
      margin: 30px 0 40px;
      padding: 60px 40px 0;
      text-align: left;
      position: relative;

      &::before {
        content: " ";
        display: block;
        width: 300px;
        background: url(https://www.iegexpo.it/templates/yootheme_iegexpo/images/x-iegexpo-icon-section-background.png)
          no-repeat;
        background-size: contain;
        position: absolute;
        left: calc(600px - 50vw);
        top: 0px;
        bottom: 0;
        z-index: -1;
      }
      &-title {
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
      }
      &-more {
        display: block;
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        width: 96px;
        height: 32px;
        border: 2px solid #2d2e33;
        text-align: center;
        cursor: pointer;
        margin: 18px 0 36px;
      }
      &-list {
        width: 1200px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
      &-item {
        position: relative;
        padding-left: 108px;
        height: 160px;
        display: flex;
        flex-direction: column;
        &-img {
          left: 0;
          width: 100px;
          height: 100px;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #000;
          overflow: hidden;
          z-index: 0;
          img {
            width: 100px;
          }
        }
        &-title {
          width: 265px;
          font-size: 20px;
          line-height: 1.4;
          font-family: RalewayIEG;
          font-weight: 700;
          text-transform: uppercase;
        }
        &-content {
          font-family: RalewayIEG;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
        &-desc {
          font-family: RalewayIEG;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        }
      }
      &-subtitle {
        font-family: RalewayIEG;
        font-size: 16px;
        line-height: 1.4;
        font-family: RalewayIEG;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 20px;
      }
      &-logo {
        text-align: center;
        img {
          width: 200px;
          height: 64px;
        }
      }
    }
    &-zg {
      &-title {
        color: var(---001, #1a1a1a);
        font-family: PingFang SC;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
        margin: 40px 0;
        text-align: left;
      }
      &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
      }
      &-item {
        width: 292px;
        height: 195px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(1) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/venue_rimini-a4203943.jpeg)
            no-repeat;
          background-size: contain;
        }
        &:nth-child(2) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/venue_vicenza-9ce3d544.jpeg)
            no-repeat;
          background-size: contain;
        }
        &:nth-child(3) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/palacongressi-rimini-6a5431e6.jpeg)
            no-repeat;
          background-size: contain;
        }
        &:nth-child(4) {
          background: url(https://www.iegexpo.it/templates/yootheme/cache/palacongressi-vicenza-092c1279.jpeg)
            no-repeat;
          background-size: contain;
        }
        &-title {
          border: 2px solid var(---000, #fff);
          background: var(--00260, rgba(0, 0, 0, 0.6));
          width: 180px;
          padding: 1px 16px;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          color: var(---000, #fff);

          &:visited {
            color: #fff;
          }
          &:hover {
            background: var(--00260, #fff);
            color: #343434;
          }
        }
      }
    }
    &-divider {
      height: 1px;
      background-color: #bfbfbf;
      width: 100vw;
      position: relative;
      left: calc(600px - 50vw);
    }
  }
}

@media (max-width: 768px) {
  .home-body {
    width: 100vw;
  }
  .home-body-header {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .home-body-left {
    width: 100vw;
    padding: 0 15px;
  }
  .home-body-right {
  }
  .home-body-video {
    padding: 0 15px;
    width: 100vw;
    height: 200px;
    video {
      width: 100%;
      height: 200px;
    }
  }
  .home-body-desc {
    flex-direction: column;
    height: 900px;
    .ant-divider {
      height: 10px !important;
    }
  }
  .home-body-jt-desc {
    padding: 0 15px;
  }
  .home-body-jt-card {
    flex-direction: column;
    margin-bottom: 10px;
    .ant-divider {
      display: none;
    }
  }
  .home-body-jt-card-img {
    width: 345px;
    height: auto;
    margin-bottom: 10px;
  }
  .home-body-jt-card-item {
    height: auto;
  }
  .home-body-jt-card-btn {
    width: 100px;
    height: 30px;
    justify-content: center;
    font-size: 13px;
    background-color: #fff;
    left: 135px;
    bottom: 100px;
    box-shadow: 0px 0px 1px 1px #e0e0e0;
  }
  .home-body-zh-list {
    display: flex;
    flex-direction: column;
  }
  .home-body-zh {
    padding: 0 15px;
    margin-bottom: 0;
  }
  .home-body-zh-title {
    font-size: 20px;
  }
  .home-body-zh-more {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  .home-body-zh-item {
    width: 345px;
    display: flex;
    flex-direction: column;
    height: 120px;
  }
  .home-body-zh-item-title {
    width: 230px;
  }
  .home-body-zh-subtitle {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .home-body-zh-logo {
    height: 64px;
    display: flex;
    justify-content: center;
    img {
      display: block;
      height: 30px;
    }
  }
  .home-body-zg {
    padding: 0 15px;
  }
  .home-body-zg-title {
    font-size: 20px;
    margin: 10px 0;
  }
  .home-body-zg-content {
    flex-direction: column;
  }
  .home-body-zg-item {
    width: 345px;
    background-size: cover !important;
    margin-bottom: 10px;
  }
}
</style>
