!(function () {
  // const { userAgent } = window.navigator;
  // if (!/(ios|iphone|ipad|android)/i.test(userAgent)) return;

  const docEl = document.documentElement;

  function setRem() {
    docEl.style.fontSize =
      docEl.clientWidth <= 768
        ? (docEl.clientWidth / 750) * 240 + "px"
        : "120px";

    const docElFontSize = docEl.style.fontSize.replace(/px/gi, "");
    const temp = window.getComputedStyle(docEl);
    const computedFontSize = temp["font-size"].replace(/px/gi, "");
    docElFontSize != computedFontSize &&
      (docEl.style.fontSize =
        (docElFontSize * docElFontSize) / computedFontSize + "px");
  }

  window.addEventListener("resize", setRem);
  window.addEventListener("pageshow", (e) => e.persisted && setRem());

  setRem();
})();
